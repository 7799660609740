const newseLatterData = [
	{
		id: '1',
		icon: { parentBg: '#beaaf7', childBg: 'rgb(131 88 255)', svg: 'icon-wallet' },
		title: ['Explore', ' Our', ' Collection'],
		text: "Browse through our extensive catalog of Pokémon Cards, Pokémon Figurines, and Dragon Ball Z Figurines. Discover exclusive and rare items to enhance your collection.",
	},
	{
		id: '2',
		icon: { parentBg: '#c4f2e3', childBg: 'rgb(16 185 129)', svg: 'icon-wallet' },
		title: ['Choose', ' Your', ' Favorites'],
		text: "Select the items you love. Whether you're looking for a specific Pokémon Card or a Dragon Ball Z Figurine, we've got you covered with detailed descriptions and images.",
	},
	{
		id: '3',
		icon: { parentBg: '#cddffb', childBg: 'rgb(66 138 248)', svg: 'icon-gallery' },
		title: ['Secure', ' Your', ' Purchase'],
		text: "Add your chosen items to your cart and proceed to checkout. Enjoy a seamless and secure payment experience tailored to collectors like you.",
	},
	{
		id: '4',
		icon: { parentBg: '#ffd0d0', childBg: 'rgb(239 68 68)', svg: 'icon-list' },
		title: ['Enjoy', ' Your', ' Collectibles'],
		text: "Receive your collectibles quickly and safely. Start showcasing your new Pokémon Cards, Pokémon Figurines, or Dragon Ball Z Figurines to fellow enthusiasts!",
	},
];

export { newseLatterData };
